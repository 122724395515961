import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const ProgressBar = ({
    completedPercent,
    color
}) => {
    return (
        <div styleName={"styles.progressWrap"}>
            <div styleName={"styles.progressTrack"}>
                <div styleName={"styles.progressRail"} style={{width: `${completedPercent}%`, background: color || "#00AA00"}} />
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    completedPercent: PropTypes.number,
    color: PropTypes.string
};
export default ProgressBar;
