import React from "react";
import styles from "./styles.css";
import Close from "./images/close.svg";
import PropTypes from "prop-types";

const FilterPills = ({filterName, onRemoveFilter, type, showCloseIcon = true, defaultText}) => {
    return (
        <div styleName={`styles.pillsContainer ${type === "white" ? "styles.pillsContainerWhite" : ""}`}>
            <p styleName={`styles.filterName ${defaultText ? "" : "styles.capitalizeText"} ${type === "white" ? "styles.filterNameWhite" : ""}`}>
                {defaultText ? filterName : (filterName || "").toLowerCase()}
            </p>
            {showCloseIcon && <img src={Close} onClick={onRemoveFilter}/>}
        </div>
    );
};

FilterPills.propTypes = {
    filterName: PropTypes.string,
    showCloseIcon: PropTypes.bool,
    onRemoveFilter: PropTypes.func,
    type: PropTypes.string,
    defaultText: PropTypes.bool
};

export default FilterPills;
