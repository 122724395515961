import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import styles from "./styles.css";
import Google from "./images/google.png";
import TrustPilot from "./images/trust.png";

const BlackFridayBanner = ({onSaleClick}) => {
    return (
        <div styleName={"styles.bannerOuter"}>
            <div className={"container"}>
                <p styleName={"styles.blackFriday"}>BLACK FRIDAY</p>
                <p styleName={"styles.saleMainText"}>SALE ENDS TODAY</p>
                <p styleName={"styles.saveAmount"}>SAVE up to $5000</p>
                <Button text="SHOW CARS ON SALE" onClick={onSaleClick} />
                <a styleName={"styles.terms"} href="https://www.plexus.co/terms/black-friday-sale/" target="_blank">T&Cs apply.</a>
            </div>
            <div styleName={"styles.logoWrapper"}>
                <a href="https://www.google.com/maps/place/CARS24+Australia/@-37.942464,138.1607232,4z/data=!4m5!3m4!1s0x0:0xdcbe0bace6f08656!8m2!3d-32.7153521!4d148.955806" target="_blank"><img src={Google} /></a>
                <a href="https://au.trustpilot.com/review/cars24.com/" target="_blank"><img src={TrustPilot} /></a>
            </div>
        </div>
    );
};

BlackFridayBanner.propTypes = {
    onSaleClick: PropTypes.func
};

export default BlackFridayBanner;
