/* eslint-disable max-statements */
import React, { useState, useEffect} from "react";
import styles from "./styles.css";
import Search from "../../shared/search";
import Button from "../../shared/button";
import { useHistory } from "react-router-dom";
import Filters from "../../../utils/filters-v2";
import PropTypes from "prop-types";
import { BANNER_TYPE, DEFAULT_CITY, LISTING_TYPE } from "../../../constants/app-constants";
import { AU_DESKTOP_EVENTS, DESKTOP_EVENT_ACTION } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import toLocaleString from "../../../utils/helpers/to-locale-string";
import { FB_EVENTS_CATEGORY } from "../../../tracking/fb-events";

const getModelOption = (modelOptions, data) => (modelOptions || []).map(
    ({displayText = "", key = ""}, index) =>
        ({  index,
            displayText,
            label: key,
            value: key,
            subOptionKey: key,
            optionKey: data.key
        }));

const MakeFilter = ({
    fetchFiltersConnect,
    updateSelectedOnScreenFilterConnect,
    updateFilterSSRStatusConnect,
    updateFilterOptionDataConnect,
    updateAllSubFilterOptionsConnect,
    updateAppliedFiltersConnect,
    selectedCity,
    selectedFilters,
    allFilters,
    config,
    resetCarListConnect,
    selectedFilterType = "make",
    reloadCarListConnect,
    updateAllFiltersVisibilityConnect,
    clearAllFiltersConnect,
    configLabels = {
        makeLabel: "Select Make",
        modelLabel: "Select Model",
        buttonLabel: "Show all cars"
    },
    isVariant,
    listingCount,
    totalAvailableCars,
    fetchListingCountConnect,
    bannerType = "",
    updateUrlOptionsConnect,
    updateListingTypeConnect,
    setListingFBEventsConnect,
    fbEventType,
    onMakeSelectCallback = () => {},
    onModelSelectCallback = () => {},
    onSubmitCallback = () => {}

}) => {
    const [makeOption, setMakeOption] = useState({});
    const [modelOption, setModelOption] = useState([]);
    const [modelValue, setModelValue] = useState("");

    const history = useHistory();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (allFilters.make) {
            setOptions(allFilters.make.options);
        }
    }, [history, allFilters]);

    useEffect(() => {
        if (allFilters.length === 0) {
            fetchFiltersConnect();
        }
        // updateSelectedOnScreenFilterConnect(selectedFilterType);
        updateFilterSSRStatusConnect(false);
        window.scrollTo(0, 0);
        clearAllFiltersConnect();
        updateUrlOptionsConnect({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const trackEvent = (type, params) => {
        const events = { action: "", params: ""};
        const isFinanceBanner =  bannerType === BANNER_TYPE.FINANCE_BANNER;
        switch (type) {
        case "showMore":
            events.action = isFinanceBanner ? AU_DESKTOP_EVENTS.FINANCE_BANNER_SHOW_ALL_CARS : DESKTOP_EVENT_ACTION.ATF_INTERACTION;
            events.params = "show_me";
            break;
        case "make":
            events.action = isFinanceBanner ? AU_DESKTOP_EVENTS.FINANCE_BANNER_SELECT_MAKE : DESKTOP_EVENT_ACTION.MAKE_SELECTED;
            events.params = params.optionKey || makeOption;
            break;
        case "model":
            events.action = isFinanceBanner ? AU_DESKTOP_EVENTS.FINANCE_BANNER_SELECT_MODEL : DESKTOP_EVENT_ACTION.MODEL_SELECTED;
            events.params = `${params.optionKey} ${params.subOptionKey}` || modelOption;
            break;
        default: events.action = "";
        }
        trackDesktopCustomEventsAU(events.action, {eventLabel: `options ${events.params}`});
    };

    useEffect(() => {
        const filters = new Filters({cityCode: selectedCity.code || DEFAULT_CITY.AU.code});
        // if (Object.keys(allFilters).length) {
        const {payload} = filters.getListingPayload({selectedFilters, filters: allFilters});
        fetchListingCountConnect({
            size: 0,
            page: 0
        }, payload);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedFilters)]);

    const getMakeData = () => (options || []).map(
        ({displayText = "", logo = {}}, index) =>
            ({ label: displayText,  value: displayText, index,
                logo: logo.dropdownImageUrl  ? fastlyUrl(config.brandImageHost, logo.dropdownImageUrl, imageTypes.default) : ""}));

    const getModelData = (mainIndex) => {
        if (makeOption.isSelected) {
            updateAllSubFilterOptionsConnect({...makeOption, isSelected: false});
        }
        const mainData = options[mainIndex];
        const subFilter =  mainData ? mainData.subFilter : {};
        const modelOptions = subFilter ? subFilter.options : [];
        const subData = getModelOption(modelOptions, mainData);
        const makeData = { filterKey: selectedFilterType, optionKey: mainData.displayText, isSelected: true};
        if (makeData.optionKey !== makeOption.optionKey) {
            setModelValue({});
        }
        setMakeOption(makeData);
        setModelOption(subData);
        trackEvent("make", makeData);
        onMakeSelectCallback("make", mainData.displayText);
        setListingFBEventsConnect(FB_EVENTS_CATEGORY.HOME_PAGE_MAKE);
        updateAllSubFilterOptionsConnect(makeData);
    };

    const onModelSelection = (selectedData) => {
        setModelValue(selectedData);
        updateAllSubFilterOptionsConnect({...makeOption, isSelected: false});
        const { optionKey, subOptionKey, displayText } = selectedData;
        const filterKey = selectedFilterType;
        const option = { filterKey, optionKey, subOptionKey, displayText, isSelected: true };
        trackEvent("model", option);
        onModelSelectCallback("model", displayText);
        setListingFBEventsConnect(FB_EVENTS_CATEGORY.HOME_PAGE_MAKE_MODEL);
        updateFilterOptionDataConnect(option);

    };

    const onClickShowCars = () => {
        resetCarListConnect();
        updateListingTypeConnect(LISTING_TYPE.ALL_CARS);
        const filters = new Filters({cityCode: selectedCity.code || DEFAULT_CITY.AU.code});
        const {relativeURL} = filters.getListingPayload({selectedFilters, filters: allFilters});
        reloadCarListConnect(true);
        updateAllFiltersVisibilityConnect(false);
        updateSelectedOnScreenFilterConnect("");
        updateAppliedFiltersConnect();
        trackEvent("showMore");
        onSubmitCallback();
        if (!fbEventType) {
            setListingFBEventsConnect(FB_EVENTS_CATEGORY.HOME_PAGE_ALL_CARS);
        }
        history.push(relativeURL);
    };
    const optionsSelected = makeOption.optionKey || (modelValue);
    return (
        <React.Fragment>
            {!isVariant &&  <p styleName="styles.title">Search all cars</p>}
            <div styleName={"styles.filterWrapper"}>
                <div styleName={"styles.searchWrapper"} className="filterMakeWrapper">
                    <Search placeholder = {configLabels.makeLabel}
                        data={getMakeData()}
                        onChange={({index}) => getModelData(index) }
                        showLogoOptions={true}
                    />
                </div>
                <div styleName={"styles.searchWrapper"} className="filterModelWrapper">
                    <Search placeholder = {configLabels.modelLabel}
                        data={modelOption}
                        onChange={onModelSelection}
                        noOptionsMessage={() => "Select a Make to see Models"}
                        value={modelValue && modelValue.value ? modelValue : ""}
                    />
                </div>
                <Button text={`show ${toLocaleString(optionsSelected ? listingCount : totalAvailableCars) || "all" } cars`} onClick={onClickShowCars} />
            </div>
        </React.Fragment>
    );
};

MakeFilter.propTypes = {
    fetchFiltersConnect: PropTypes.func,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    updateFilterSSRStatusConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    updateAllSubFilterOptionsConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    selectedFilters: PropTypes.array,
    allFilters: PropTypes.object,
    resetCarListConnect: PropTypes.func,
    selectedFilterType: PropTypes.string,
    makeFilterData: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    updateAllFiltersVisibilityConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    configLabels: PropTypes.object,
    config: PropTypes.object,
    isVariant: PropTypes.bool,
    listingCount: PropTypes.number,
    totalAvailableCars: PropTypes.number,
    fetchListingCountConnect: PropTypes.func,
    bannerType: PropTypes.string,
    updateUrlOptionsConnect: PropTypes.func,
    updateListingTypeConnect: PropTypes.func,
    setListingFBEventsConnect: PropTypes.func,
    fbEventType: PropTypes.string,
    onMakeSelectCallback: PropTypes.func,
    onModelSelectCallback: PropTypes.func,
    onSubmitCallback: PropTypes.func
};
export default MakeFilter;
