import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_BOOKED_TASKS_LIST
    FETCH_BOOKED_TASKS_LIST_SUCCESS
    FETCH_BOOKED_TASKS_LIST_FAILURE
`,
    {
        prefix: "bookedTasks/"
    }
);
