/* eslint-disable no-magic-numbers */
import parseCookie from "../../../utils/helpers/parse-cookie";
import { COOKIE_SEARCH_KEYWORDS } from "../../../constants/app-constants";

export const getSegregatedOptions = (data) => {
    const groupedOptions = [];
    const modelList = {
        label: "Models",
        options: []
    };
    const makeList = {
        label: "Brands",
        options: []
    };
    data.forEach((item, position) => {
        let displayOptions = {};
        const { suggestion, type, moreInfo = {}, make  } = item || {};
        displayOptions = {
            label: suggestion,
            value: suggestion,
            position: position + 1,
            group: "query",
            type: type === "model" ? "Model" : "Make",
            imageUrl: moreInfo.searchImageUrl,
            modelCount: (data || []).filter(it => it.make === make && it.type === "model").length
        };
        if (type === "model") {
            modelList.options.push(displayOptions);
        } else {
            makeList.options.push(displayOptions);
        }
    });
    if (makeList.options.length) {
        groupedOptions.push(makeList);
    }
    if (modelList.options.length) {
        groupedOptions.push(modelList);
    }
    return groupedOptions;
};

export const updateSearchList = (selectedValue) => {
    const { value } = selectedValue;
    if (value) {
        if (parseCookie(COOKIE_SEARCH_KEYWORDS)) {
            let options = JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
            options.forEach((item, index) => {
                if (item.value === value) {
                    options.splice(index, 1);
                }
            });
            if (options.length === 6) options.pop(); //for fifo
            options = [selectedValue, ...options]; //treating it like queue instead of stack
            document.cookie = `${COOKIE_SEARCH_KEYWORDS}=${JSON.stringify(options)};path=/`;
        } else {
            document.cookie = `${COOKIE_SEARCH_KEYWORDS}=${JSON.stringify([selectedValue])};path=/`;
        }
    }
};

export const getDefaultSuggestions = (suggestions) => {
    const formattedSuggestions = (suggestions || []).map((suggestion, index) => ({
        value: suggestion,
        label: suggestion,
        type: "Make",
        group: "Suggested Brands",
        position: index + 1
    }));

    return [{
        label: "Suggested Brands",
        options: formattedSuggestions
    }];

};

export const getRecentSearch = () => {
    return JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
};
export const getV2SuggestionOptions = (data) => {
    return data.map(item => ({label: item.suggestionText, value: item.suggestionText}));
};
