import { resetReducerKeys } from "../../../constants/app-constants";
import Types from "./types";

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});
const resetCarList = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_CAR_LIST });
};

export { reloadCarList, resetCarList };
