/* eslint-disable no-magic-numbers */
import React from "react";
import useCountDown from "../../../hooks/use-countdown";
import PropTypes from "prop-types";
import ProgressBar from "../progress-bar";

const ClockTimerBar = ({
    expiryTime,
    startTime,
    displayExtensionBeforeTimeoutInMins
}) => {
    // eslint-disable-next-line no-unused-vars
    const [days, hours, minutes, seconds, fullHours, timeCompletePercentage, lessMinsLeft] = useCountDown(expiryTime, startTime, displayExtensionBeforeTimeoutInMins);
    return (
        <ProgressBar
            completedPercent={timeCompletePercentage > 100 ? 100 : timeCompletePercentage}
            color = {lessMinsLeft ? "#EC5856" : "#0082D0"}
        />
    );
};

ClockTimerBar.propTypes = {
    expiryTime: PropTypes.string,
    timerSize: PropTypes.number,
    displayExtensionBeforeTimeoutInMins: PropTypes.number,
    startTime: PropTypes.string,
    strokeWidth: PropTypes.number,
    timeFontSize: PropTypes.string,
    textFontSize: PropTypes.string
};

export default ClockTimerBar;
