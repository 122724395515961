import React from "react";
import styles from "./styles.css";
// import CARICON from "./images/caricon.svg";
import PropTypes from "prop-types";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import ZeroDpReBookCar from "../zero-dp-rebook-car";

const ZeroDpMyBookingAvailableCard = ({
    vehicleDetail,
    orderId,
    content
}) => {
    const { booked, reserved, listingActive } = content || {};
    const { year, make, model, config, mainImage} = vehicleDetail || {};
    const hasMainImage = (mainImage || {}).path;
    const carImageUrl = hasMainImage
        ? fastlyUrl(config.imageHost, mainImage.path, imageTypes.auNudgesSmall)
        : null;
    const carNotAvailable = reserved || booked || !listingActive;
    return (
        <div styleName={"styles.wrapper"}>
            <div className="media" styleName={"styles.carWrapper"}>
                <div className="media-body">
                    <p styleName={"styles.carNames"}>{year} {make} {model}</p>
                    <ul styleName={"styles.carText"}>
                        {!carNotAvailable && <li>Available, not booked yet</li>}
                    </ul>
                </div>
                <img src={carImageUrl} />
            </div>
            <div styleName={"styles.driveAwayWrap"}>
                <p styleName={"styles.driveAway"}>Time <span>ran out</span> before you could have completed all the tasks.
                    {!carNotAvailable && <strong>You can still book it by paying us a deposit amount</strong>}
                </p>
            </div>
            {!carNotAvailable && <ZeroDpReBookCar oldOrderId={orderId} content={vehicleDetail} />}
        </div>
    );
};

ZeroDpMyBookingAvailableCard.propTypes = {
    vehicleDetail: PropTypes.object,
    content: PropTypes.object,
    orderId: PropTypes.string
};

export default ZeroDpMyBookingAvailableCard;
