import { appUrl } from "../../constants/url-constants";
import qs from "querystring";
import dashedLowercase from "./dashed-lowercase";

// eslint-disable-next-line complexity
export default ({ carName, year, carId, city, pincode, isCheckout }) => {
    const url = ["buy", "used"];
    const carText = (carName || "");
    if (carText.includes("Suzuki")) carText.replace("Suzuki ", "");
    url.push(carText.split(" ").join("-"));
    if (year) { url.push(year); }
    url.push("car");
    if (city) { url.push((city || "").split(" ").join("-")); }
    url.push(carId);
    const normalizedURL = url.join("-");
    return {
        absoluteURL: `${appUrl()}/${normalizedURL}/${isCheckout ? `checkout/` : ``}${pincode ? `?pinId=${pincode}` : ``}`,
        relativeURL: `/${normalizedURL}/${isCheckout ? `checkout/` : ``}${pincode ? `?pinId=${pincode}` : ``}`
    };
};

export const carDetailUrlAu = ({ carName, carId, isCheckout }) => {
    const url = ["buy", "used"];
    const carText = (carName || "");
    url.push(carText.split(" ").join("-"));
    url.push("car");
    url.push(carId);
    const normalizedURL = url.filter(Boolean).join("-");
    return {
        absoluteURL: `${appUrl()}/${normalizedURL}/${isCheckout ? `checkout/` : ""}`,
        relativeURL: `/${normalizedURL}/${isCheckout ? `checkout/` : ""}`
    };
};

export const getCarDetailsPageURL = (content = {}) => {
    return `/buy-used-${dashedLowercase(content.make)}-${dashedLowercase(content.model)}-car-${content.appointmentId}`;
};

export const getCheckoutPageURL = (route = "", c2bOrderId = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/sell-your-car/${route}/${c2bOrderId}${queryStr}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getBookInspectionPageURL = (route = "", c2bOrderId = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/book-inspection/${c2bOrderId}/${route}${queryStr}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};

export const getMyBookingCarDetailsURL = (appointmentId, route = "", query = null) => {
    const queryStr = query ? `?${qs.stringify(query)}` : "";
    const path = `/my-bookings-${appointmentId}/${route}${queryStr}`;
    return {
        absoluteURL: `${appUrl()}${path}`,
        relativeURL: path
    };
};
