import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeNudgeList, fetchNudgeList, setNudgeDisplay } from "./actions";
import NudgeCards from "./component";

const mapStateToProps = ({
    user: {secureToken, isLoggedIn},
    nudgeAlertList: {nudgeList, closedNudgesId}
}) => ({
    secureToken,
    nudgeList,
    closedNudgesId,
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchNudgeListConnect: fetchNudgeList,
    setNudgeDisplayConnect: setNudgeDisplay,
    closeNudgeListConnect: closeNudgeList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NudgeCards);
