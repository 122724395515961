import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import styles from "./styles.css";
import Google from "./images/google.png";
import TrustPilot from "./images/trust.png";
import Product from "./images/product.png";
import MakeFilter from "../make-filter";
import GetPreApprovalCardChristmasLanding from "../get-pre-approval-card-christmas-landing";

const ChristmasSaleBanner = ({onSaleClick, sellCarURL}) => {
    return (
        <React.Fragment>
            <div styleName={"styles.bannerOuter"}>
                <div className={"container"}>
                    <p styleName={"styles.christmasHeading"}>12 Cars. 12 Deals. </p>
                    <p styleName={"styles.christmasSubHeading"}>A surprise discount each day. Selected by Santa.</p>
                    <div styleName={"styles.buttonContainer"}>
                        <Button text="SHOW Today's FESTIVE DEALs" onClick={onSaleClick}/>
                        <a styleName={"styles.terms"} href="https://www.plexus.co/terms/12-cars-of-xmas-offer" target="_blank">T&Cs apply.</a>
                    </div>
                    <div styleName={"styles.logoWrapper"}>
                        <a href="https://www.productreview.com.au/listings/cars24-australia" target="_blank">
                            <img src={Product} styleName={"styles.imgOne"} />
                        </a>
                        <a href="https://www.google.com/maps/place/CARS24+Australia/@-37.942464,138.1607232,4z/data=!4m5!3m4!1s0x0:0xdcbe0bace6f08656!8m2!3d-32.7153521!4d148.955806" target="_blank">
                            <img src={Google} styleName={"styles.imgTwo"} />
                        </a>
                        <a href="https://au.trustpilot.com/review/cars24.com/" target="_blank">
                            <img src={TrustPilot} styleName={"styles.imgThree"} />
                        </a>
                    </div>
                </div>
            </div>
            <div styleName={"styles.financeWrapper"}>
                <div className="container">
                    <div className="row">
                        <div styleName={"styles.leftContainer"}>
                            <MakeFilter />
                            <p styleName={"styles.notLooking"}>Not looking to buy? <a href={sellCarURL}>Sell your car</a></p>
                        </div>
                        <div styleName={"styles.rightContainer"}>
                            <GetPreApprovalCardChristmasLanding />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

ChristmasSaleBanner.propTypes = {
    onSaleClick: PropTypes.func,
    sellCarURL: PropTypes.string
};

export default ChristmasSaleBanner;
