import React from "react";
import styles from "./styles.css";
import MainText from "./images/heading.webp";
import BannerImage from "./images/banner-img.webp";
import SearchRevamp from "../search-revamp";
import { Helmet } from "react-helmet";

const EofySaleBannerHome = () => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={BannerImage} as={"image"} type="image/jpg" />
            </Helmet>
            <div styleName={"styles.bannerOuter"}>
                <div className="container">
                    <img src={MainText} alt="End of financial year sale text" />
                    <p styleName={"styles.subHeading"}>Drive into Wheely Good Savings!</p>
                    <SearchRevamp isHomePage = {true}/>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EofySaleBannerHome;
