import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTradeInAnotherCarTriggered } from "../trade-in-your-car-wrapper/actions";
import ValuationExpiredPopup from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setTradeInAnotherCarTriggeredConnect: setTradeInAnotherCarTriggered
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValuationExpiredPopup);

