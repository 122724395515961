import { BffDataAU } from "../../../service";
import Types from "./types";

const fetchBookedTasksListInitiated = () => ({
    type: Types.FETCH_BOOKED_TASKS_LIST
});

const fetchBookedTasksListSuccess = ({data}) => ({
    type: Types.FETCH_BOOKED_TASKS_LIST_SUCCESS,
    data
});

const fetchBookedTasksListFailure = (error) => ({
    type: Types.FETCH_BOOKED_TASKS_LIST_FAILURE,
    error
});

const fetchBookedTasksList = (token) => (dispatch, getState) => {
    const {user: {secureToken}} = getState();
    dispatch(fetchBookedTasksListInitiated());
    return new Promise((resolve, reject) => {
        BffDataAU.fetchBookedTasksList(token || secureToken).then((response) => {
            dispatch(fetchBookedTasksListSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchBookedTasksListFailure(error));
            reject(error);
        });
    });
};

export {
    fetchBookedTasksList
};
