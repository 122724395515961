import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import Button from "../../shared/button";
import Expired from "./images/expired-icon.png";

const ValuationExpiredPopup = ({
    item = {},
    handleClose = () => {},
    setTradeInAnotherCarTriggeredConnect
}) => {

    const history = useHistory();
    const {subTitle = "", title = "", actionCta = ""} = item;

    const handleModelClose = () => {
        handleClose(item);
    };

    const handleOnApplyAgain = () => {
        setTradeInAnotherCarTriggeredConnect(true);
        handleModelClose();
        history.push("/trade-in/");
    };

    return (
        <Modal close={handleModelClose} isOpen={true}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.closeContainer"}>
                    <CloseButton type="grey" onClickHandler={handleModelClose} />
                </div>
                <p styleName={"styles.heading"}>{title}</p>
                <div styleName={"styles.expiredImage"}><img src={Expired} /></div>
                <p styleName={"styles.subHeading"}>{subTitle}</p>
                <Button text={actionCta} onClick={handleOnApplyAgain} />
            </div>
        </Modal>
    );
};

ValuationExpiredPopup.propTypes = {
    item: PropTypes.object,
    handleClose: PropTypes.func,
    setTradeInAnotherCarTriggeredConnect: PropTypes.func
};

export default ValuationExpiredPopup;
