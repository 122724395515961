import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    UPDATE_SSR_STATUS
    RELOAD_CAR_LIST
`,
    {
        prefix: "carlisting/"
    }
);
