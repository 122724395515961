/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect } from "react";
import styles from "./styles.css";
// import MakeFilter from "../make-filter";
import PropTypes from "prop-types";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import {  DEFAULT_CITY, SALE_KEY } from "../../../constants/app-constants";
import NudgeCard from "../nudge-cards";
import { useHistory } from "react-router";
import TrackImpression from "../../shared/track-impression";
import BookedTasks from "../booked-tasks";
import LandingBannerSellRevamp from "../landing-banner-sell-revamp/component";
import BlackFridayBanner from "../black-friday-banner/component";
import Filters from "../../../utils/filters-v2";
import { ORDER_STATUS } from "../../../constants/checkout-constants";
import ChristmasSaleBanner from "../christmas-sale-banner/component";
import { appUrl } from "../../../constants/url-constants";
import { API_VERSION_V2 } from "../../../service/api-version";

const HomeBanner = ({
    fetchConfigConnect,
    saleConfig,
    isLoggedIn,
    bookedTasks,
    secureToken,
    fetchBookedTasksListConnect,
    updateFilterOptionDataConnect,
    reloadCarListConnect,
    selectedCity,
    allFilters,
    fetchFiltersConnect,
    bookings,
    getPreApprovalLoanDetailsConnect
    // setCurrentScreenConnect
}) => {
    const history = useHistory();
    const { cars: myBookingsOrder } = bookings || {};
    const myBookingsLastOrder = Array.isArray(myBookingsOrder) && myBookingsOrder[0];
    const {
        booked,
        reserved,
        zeroDp,
        bookingState
    } = myBookingsLastOrder || {};
    const carAvailable = !booked && !reserved && zeroDp && bookingState === ORDER_STATUS.CANCELED;
    const sellCarURL = `${appUrl()}/sell-your-car?utm_source=home-banner&utm_medium=Msite&utm_campaign=Homepage-banner-redirection&utm_id=Main-CTA-Redirect`;

    /**
     * showHomepageRevamp will be true if the user is in the variant B of the experiment
     */

    // const showPreapprovalForSellCarBanner = (bookedTasks || []).length > 0 && !!isLoggedIn;
    const { key: saleKey, saleLive, currentType } = saleConfig?.data || saleConfig || {};
    const SALE_COMPONENT = {
        [SALE_KEY.BLACK_FRIDAY]: {
            component: BlackFridayBanner
        },
        [SALE_KEY.CHRISTMAS_SALE]: {
            component: ChristmasSaleBanner
        }
    };

    useEffect(() => {
        if (saleConfig && !saleConfig.isSSR) {
            fetchConfigConnect();
        }
        fetchFiltersConnect(API_VERSION_V2);        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (secureToken) {
            fetchBookedTasksListConnect(secureToken);
            getPreApprovalLoanDetailsConnect({fetchListingDetails: true});

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken]);

    const onSaleClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SHOW_EASTER_EGG);
        if (currentType === "onSale") {
            const data = { filterKey: "promotion", optionKey: "On sale", isSelected: true, displayText: "On sale" };
            updateFilterOptionDataConnect(data);
            // updateAppliedFiltersConnect();
            reloadCarListConnect(true);
            window.setTimeout(() => {
                const filters = new Filters({ cityCode: selectedCity.code || DEFAULT_CITY.AU.code });
                const { relativeURL } = filters.getListingPayload({ selectedFilters: [data], filters: allFilters });
                history.push(relativeURL);
            }, 0);
        }
    };

    const isTaskAvailable = (bookedTasks || []).length > 0;

    const saleComponent = saleLive && SALE_COMPONENT[saleKey] && SALE_COMPONENT[saleKey].component;

    const Component = saleComponent || LandingBannerSellRevamp;

    return (
        <TrackImpression event={{ name: "landingBannerViewd" }}>
            <div styleName={(saleLive && isLoggedIn && isTaskAvailable) ? "styles.bottomMargin" : ""}>
                <div>
                    {secureToken &&
                        <div styleName={"styles.nudgeCardContainer styles.nudgeCardContainerNew"}>
                            <NudgeCard />
                        </div>
                    }
                    <Component
                        isLoggedIn={isLoggedIn}
                        bookedTasks={isLoggedIn ? bookedTasks : []}
                        onSaleClick={onSaleClick}
                        carAvailable={carAvailable}
                        sellCarURL={sellCarURL}
                        saleLive={saleLive}
                    />
                </div>
                {!!isLoggedIn && isTaskAvailable &&
                <div styleName={saleLive ? "styles.marginTop" : ""}>
                    <BookedTasks saleLive={saleLive}/>
                </div>
                }

                {/* Since showHomepageRevamp will always be true as VARIANT_B is always true, the component GetPreApprovalCardLanding will never be rendered */}

                {/* {((bookedTasks || []).length !== 0 && !saleLive) && !preApproved && isLoggedIn && !showHomepageRevamp &&
                <section styleName={"styles.makeWrapper"}>
                    <div className={"container"}>
                        <React.Fragment>
                            <div styleName={`styles.getApprovalWrapper ${saleLive ? "styles.saleApprovalWrapper" : ""}`}>
                                <GetPreApprovalCardLanding isVariant={false} bannerType={BANNER_TYPE.FINANCE_BANNER} />
                            </div>
                        </React.Fragment>
                    </div>
                </section>
                } */}
            </div>
        </TrackImpression>
    );
};

HomeBanner.propTypes = {
    setShowAllowTrackingConnect: PropTypes.func,
    showAllowTracking: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    secureToken: PropTypes.string,
    fetchConfigConnect: PropTypes.func,
    saleConfig: PropTypes.object,
    sendAppLinkConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    allFilters: PropTypes.object,
    selectedCity: PropTypes.string,
    setCurrentScreenConnect: PropTypes.func,
    bookings: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    bookedTasks: PropTypes.array,
    fetchBookedTasksListConnect: PropTypes.func,
    fetchFiltersConnect: PropTypes.func,
    getPreApprovalLoanDetailsConnect: PropTypes.func
};
export default HomeBanner;

