import { BffDataAU, NudgeAlertService } from "../../../service";
import Types from "./types";

const setNudgeDisplay = (data) => ({
    type: Types.SET_NUDGE_DISPLAY,
    data
});

const fetchNudgeListInitiated = () => ({
    type: Types.FETCH_NUDGE_LIST
});

const fetchNudgeListSuccess = ({data}) => ({
    type: Types.FETCH_NUDGE_LIST_SUCCESS,
    data
});

const fetchNudgeListFailure = (error) => ({
    type: Types.FETCH_NUDGE_LIST_FAILURE,
    error
});

const fetchNudgeList = (token) => (dispatch, getState) => {
    const {user: {secureToken}} = getState();
    dispatch(fetchNudgeListInitiated());
    return new Promise((resolve, reject) => {
        BffDataAU.fetchNudgeList(token || secureToken).then((response) => {
            dispatch(fetchNudgeListSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(fetchNudgeListFailure(error));
            reject(error);
        });
    });
};

const closeNudgeListInitiated = () => ({
    type: Types.CLOSE_NUDGE_LIST
});

const closeNudgeListSuccess = ({data}) => ({
    type: Types.CLOSE_NUDGE_LIST_SUCCESS,
    data
});

const closeNudgeListFailure = (error) => ({
    type: Types.CLOSE_NUDGE_LIST_FAILURE,
    error
});

const closeNudgeList = (appointmentIds, sourceAlertType) => (dispatch, getState) => {
    const {user: {secureToken}} = getState();
    dispatch(closeNudgeListInitiated());
    return new Promise((resolve, reject) => {
        NudgeAlertService.closeNudgeList(secureToken, {appointmentIds, sourceAlertType}).then((response) => {
            dispatch(closeNudgeListSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(closeNudgeListFailure(error));
            reject(error);
        });
    });
};

export {
    fetchNudgeList,
    setNudgeDisplay,
    closeNudgeList
};
