import { ListingServiceAU, ListingServiceAUV2 } from "../../../service";
import { API_VERSION_V2 } from "../../../service/api-version";

const fetchAutoSuggestions = (payload, version) => () => {
    return new Promise((resolve) => {
        if (version === API_VERSION_V2) {
            ListingServiceAUV2.fetchAutoSuggestionsRobustRanking(payload).then((response) => resolve(response.data));
        } else {
            ListingServiceAU.fetchAutoSuggestions(payload).then((response) => resolve(response.data));
        }
    });
};

export { fetchAutoSuggestions };
