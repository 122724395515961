import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import SearchRevamp from "../search-revamp";
import EofySaleBannerHome from "../eofy-sale-banner-home/component";
import BANNERIMG from "./images/banner2.png";

const LandingBannerSellRevamp = (props) => {
    const { saleLive } = props;

    return (
        saleLive ? <EofySaleBannerHome /> :
            <div styleName="styles.wrapper">
                {!saleLive && <img styleName={"styles.imgWraaper"} src={BANNERIMG} />}
                <div className="container">
                    <div styleName={"styles.bgOuter"}>
                        <p styleName={"styles.text"}>Love it or return it in 7 days. 100% money<br /> back guarantee.</p>
                    </div>
                    <SearchRevamp isHomePage={true} />
                </div>
            </div>
    );
};

LandingBannerSellRevamp.propTypes = {
    saleLive: PropTypes.bool
};

export default LandingBannerSellRevamp;
